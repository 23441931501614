import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {
        generateCompanyList: [],
        generateJwnetItems: [],
        routesInfo: [],
        prefectures: [],
        routeDetailInfo: {},
    };
  },
  methods: {
    //事前協議書取得API
    priorConsultationDetailApi(id) {

      const URL = API_URL.PRIORCONSULTATION.DETAIL + id;
      const v = this.validate;
      return axios
          .get(URL)
          .then((res) => {
              this.form = res.data;
              this.selectedRouteInfoList = this.form.routeList;
              v.alertValueList = [];
              v.countTermEndDateList = [];
              v.countTermStartDateList = [];

              if (res.data.countTermList.length) {
                  res.data.countTermList.forEach(() => {
                      v.countTermStartDateList.push({ isValid: true, errMsg: "" });
                      v.countTermEndDateList.push({ isValid: true, errMsg: "" });
                      v.alertValueList.push({ isValid: true, errMsg: "" });
                  })
              }
              var entrustIdsList = res.data.routeList.map((r) => r.entrustId);
              this.form.entrustIds = entrustIdsList.filter(
                (val, index) => entrustIdsList.indexOf(val) == index
              ).join(",");
              const routeList = res.data.routeList;
              this.form.routeList = [];
              for (let id in routeList) {
                  this.form.routeList.push({id: routeList[id].routeId});
              }

          })
          .catch((err) => {
              console.log(err);
          });
    },

    // 排出事業者選択肢取得API
    getGeneratorApi() {
      return axios
          .get(API_URL.ENTRUST.GENERATE_SELECT_COMPANY)
          .then((res) => {
            this.generateCompanyList = res.data.generateCompanyList;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    // 都道府県選択肢取得API   DOMAIN.PREFECTURES
    getPrefecturesApi() {
      return axios
          .get(API_URL.DOMAIN.PREFECTURES)
          .then((res) => {
              this.prefectures = res.data.prefectures;
          })
          .catch((err) => {
              console.log(err);
          });
    },

    //ファイルアップロードAPI
    uploadApi(file) {
      const params = new FormData();
      params.append("file", file);
      params.append("fileName", file.name);
      return axios
          .post(API_URL.COMMON.FILE_UPLOAD, params, {
              headers: {
                  "Content-Type": "multipart/form-data",
              },
          })
          .then((res) => {
              return res.data.filePath;
          });
    },

  },
};
